import React from 'react';
import PropTypes from 'prop-types';

const ReviewButton = ({ onClick, text, className, isOpen, type, loading }) => {
  const formLoading = loading && type === 'submit';

  return (
    <button
      className={className}
      type={type}
      onClick={onClick ? () => onClick(isOpen) : () => {}}
      disabled={formLoading}
    >
      {text}
      {formLoading && <div className='spinner' />}
    </button>
  );
};

export default ReviewButton;

ReviewButton.propTypes = {
  onClick: PropTypes.func,
  text: PropTypes.string,
  className: PropTypes.string,
  isOpen: PropTypes.bool,
  type: PropTypes.string,
  loading: PropTypes.bool
};
