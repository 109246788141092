import React from 'react';
import PropTypes from 'prop-types';

import './Navigation.scss';

const Navigation = ({ slides, setSlide, slide }) => {
  return (
    <div className='navigation'>
      <div
        className='prev'
        onClick={
          slide === 0
            ? () => setSlide(slides.length - 1)
            : () => setSlide(slide - 1)
        }
      />
      {slides.map((_, index) => (
        <div
          onClick={() => setSlide(index)}
          key={index}
          className={`dot dot-${index === slide ? 'active' : 'not-active'}`}
        />
      ))}
      <div
        className='next'
        onClick={
          slide === slides.length - 1
            ? () => setSlide(0)
            : () => setSlide(slide + 1)
        }
      />
    </div>
  );
};

export default Navigation;

Navigation.propTypes = {
  slides: PropTypes.array,
  setSlide: PropTypes.func,
  slide: PropTypes.number
};
