/* eslint-disable max-len */
/* eslint-disable sonarjs/cognitive-complexity */
import React, { useCallback, useReducer, useRef } from 'react';

import SocialIcon from './SocialIcon';
import ItemBlock from './ItemBlock';
import { checkEmail } from './api';
import { reducer, initState } from './state';
import SOCIALS from './socials';
import SubmitButton from './SubmitButton';

import '../Button/Button.scss';
import './VerifyEmailForm.scss';

const EMAIL_RE = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
const validateEmail = email =>
  typeof email === 'string' && EMAIL_RE.test(email);

function fetchEmailAPI(state, dispatch) {
  dispatch({ type: 'FETCHING' });
  checkEmail(state.emailValue)
    .then(data => {
      if (data.code && +data.code === 500) {
        dispatch({ type: 'FETCH_ERROR' });
      } else if (data.code && +data.code === 429) {
        dispatch({ type: 'FETCH_ERROR' });
      } else if (data.code && +data.code === 404) {
        dispatch({ type: 'NOT_EXISTS' });
      } else if (data.smtp && !data.smtp.exists) {
        dispatch({ type: 'NOT_EXISTS' });
      } else {
        dispatch({ type: 'SET_FETCH_DATA', payload: data });
      }
    })
    .catch(() => dispatch({ type: 'FETCH_ERROR' }));
}

function linkFromState(state, social) {
  if (state[social])
    return state[social].entry[0].profileUrl || state[social].profile_link;
  if (state.gravatar && state.gravatar.entry[0].accounts) {
    const acc = state.gravatar.entry[0].accounts.find(
      acc => acc.shortname === social
    );
    if (acc) return acc.url;
  }
}

const VerifyEmailForm = () => {
  const inputRef = useRef();
  const [state, dispatch] = useReducer(reducer, initState());

  const handleSubmit = useCallback(
    e => {
      e.preventDefault();
      if (!validateEmail(state.emailValue)) {
        dispatch({ type: 'INVALID_EMAIL' });
        return;
      }
      fetchEmailAPI(state, dispatch);
      inputRef.current.value = '';
    },
    [state]
  );

  const inputBlurHandler = useCallback(e => {
    dispatch({ type: 'SET_EMAIL', payload: e.target.value });
  });

  const {
    processedEmail,
    fetchData,
    fetchError,
    isEmailValid,
    isFetching,
    emailExists
  } = state;

  const tickState = <span className='tick' />;
  const crossState = <span className='cross' />;

  const blocksData = fetchData
    ? {
        Exists: fetchData.mxExists ? tickState : crossState,
        'Trusted Rate': fetchData.trustRate.toString() + '%',
        SMTP: fetchData.smtpExists ? tickState : crossState,
        'Is not SMTP Catch-All': !fetchData.isNotSmtpCatchAll
          ? tickState
          : crossState,
        'Is not Disposable': !fetchData.isNotDisposable ? tickState : crossState
      }
    : {};

  const classesEmailForm = processedEmail
    ? 'email-results-form shown'
    : 'email-results-form';

  const classesEmailBlock = !emailExists
    ? 'email-results-block email-not-exist'
    : 'email-results-block';

  return (
    <>
      <form
        className='email-validate-form'
        id='emailForm'
        onSubmit={handleSubmit}
      >
        <h3 className='email-validate-title'>
          {'E-mail address for verifying'}
        </h3>
        <div className='email-validate-input'>
          <input
            type='email'
            placeholder='E-mail'
            ref={inputRef}
            onChange={inputBlurHandler}
            required
          />
          {!isEmailValid && <p className='input-error'>{'Fill the email'}</p>}
          <SubmitButton
            className='btn btn-primary'
            text='Verify'
            type='submit'
          />
        </div>
      </form>

      <div className={classesEmailForm}>
        <h3 className='email-results-title'>{'Entered e-mail verification'}</h3>
        <div className={classesEmailBlock}>
          {!isFetching && !processedEmail && !fetchError && (
            <>
              <img
                className='verify-icon verify-icon-not-entered'
                alt='Verify icon'
              />
              <p className='verify-message'>E-mail was not entered yet.</p>
            </>
          )}

          {isFetching && <div className='spinner' />}

          {!emailExists && (
            <>
              <p className='checked-mail-label'>
                {'Email does not exist: '}
                <span className='checked-mail-result'>{processedEmail}</span>
              </p>
              <img
                className='verify-icon verify-icon-doesnt-exist'
                alt='Verify icon'
              />
              <p className='verify-message verify-message-doesnt-exist' />
            </>
          )}

          {fetchError && (
            <p className='out-of-limit-text'>
              {'Your IP reached the limit of requests'}
            </p>
          )}

          {!isFetching && processedEmail && emailExists && (
            <>
              <img className='verify-icon verify-icon-email-exist' />
              <p className='checked-mail-label'>
                {'Email exist: '}
                <span className='checked-mail-result'>{processedEmail}</span>
              </p>
            </>
          )}

          {fetchData && (
            <div className='results-block'>
              {Object.keys(blocksData).map(name => (
                <ItemBlock key={name} name={name} value={blocksData[name]} />
              ))}
              <div className='results-icons'>
                {Object.keys(SOCIALS).map(name => {
                  const href = linkFromState(fetchData, name);
                  return (
                    <SocialIcon
                      key={name}
                      to={href ? href : '#'}
                      name={name}
                      active={Boolean(href)}
                    />
                  );
                })}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default VerifyEmailForm;
