/* eslint-disable max-len */
const EMAIL_REG = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
const IMAGE_REG = /[/.](gif|jpg|jpeg|png)$/i;

export const validateEmail = email =>
  typeof email === 'string' && EMAIL_REG.test(email);

export const validateImage = file => IMAGE_REG.test(file);

export const validateField = value =>
  typeof value === 'string' && value.length >= 2;
