import React, { useState, useCallback } from 'react';

import ReviewButton from './ReviewButton';
import ReviewModal from './ReviewModal';

const Review = () => {
  const [isModalOpen, openModal] = useState(false);

  const modalOpen = useCallback(isOpen => {
    openModal(isOpen);
    document.getElementsByTagName('body')[0].classList.toggle('fixed');
  }, []);

  return (
    <>
      <ReviewButton
        onClick={modalOpen}
        text='Write a Review'
        className='open-review-modal primary-button'
        isOpen
        type='button'
      />
      {isModalOpen && (
        <ReviewModal title='A new review' openModal={modalOpen} />
      )}
    </>
  );
};

export default Review;
