/* eslint-disable no-console */
/* eslint-disable sonarjs/cognitive-complexity */
import React, { useCallback, useReducer, useState } from 'react';
import PropTypes from 'prop-types';

import { initState, reducer } from './Reducer';
import { validateEmail, validateField, validateImage } from './Functions';
import { checkEmail } from '../Common/VerifyEmailForm/api';
import FileUploader from './FileUploader';
import ReviewInput from './ReviewInput';
import ReviewTextArea from './ReviewTextArea';
import ReviewButton from './ReviewButton';
import ReviewPhoto from './ReviewPhoto';
import Thanks from './Thanks';

import './ReviewForm.scss';

const ReviewForm = ({ openModal, title }) => {
  const [isFeedbackCorrect, setFeedbackCorrect] = useState(false);
  const [
    {
      nameValue,
      emailValue,
      workPositionValue,
      reviewValue,
      imageValue,
      isNameValid,
      isEmailValid,
      isEmailExists,
      isWorkPositionValid,
      isReviewValid,
      isImageValid,
      isImageExists,
      fetching,
      formSending
    },
    dispatch
  ] = useReducer(reducer, initState());

  const handleSubmit = useCallback(
    e => {
      e.preventDefault();

      if (imageValue === '') {
        dispatch({ type: 'NOT_EXISTS_IMAGE' });
      }

      if (
        nameValue &&
        emailValue &&
        workPositionValue &&
        reviewValue &&
        imageValue &&
        isNameValid &&
        isEmailValid &&
        isEmailExists &&
        isWorkPositionValid &&
        isReviewValid &&
        isImageValid &&
        isEmailExists &&
        !fetching
      ) {
        const data = {
          name: nameValue,
          email: emailValue,
          workPosition: workPositionValue,
          photo: imageValue,
          review: reviewValue
        };

        const formData = Object.keys(data).reduce((sendData, name) => {
          sendData.append(name, data[name]);
          return sendData;
        }, new FormData());

        dispatch({ type: 'FORM_SENDING' });

        fetch('/sendMail', {
          method: 'POST',
          body: formData
        })
          .then(res => {
            res.text();
            dispatch({ type: 'FORM_SENT' });
          })
          .then(() => setFeedbackCorrect(true))
          // eslint-disable-next-line no-console
          .catch(e => console.error(e));
      }
    },
    [
      nameValue,
      emailValue,
      workPositionValue,
      reviewValue,
      imageValue,
      isNameValid,
      isEmailValid,
      isEmailExists,
      isWorkPositionValid,
      isReviewValid,
      isImageValid,
      fetching
    ]
  );

  const checkEmailExists = useCallback(() => {
    dispatch({ type: 'FETCHING' });
    checkEmail(emailValue)
      .then(data => {
        if (data.code && +data.code === 404) {
          dispatch({ type: 'NOT_EXISTS_EMAIL' });
        } else if (data.smtp && !data.smtp.exists) {
          dispatch({ type: 'NOT_EXISTS_EMAIL' });
        } else {
          dispatch({ type: 'EXISTS_EMAIL' });
        }
      })
      .catch(err => console.error(err));
  }, [emailValue]);

  const validateFields = useCallback(
    field => {
      const { name: imageName } = imageValue;
      switch (field) {
        case 'Name':
          if (!validateField(nameValue)) {
            dispatch({ type: 'INVALID_NAME' });
          } else {
            dispatch({ type: 'VALID_NAME' });
          }
          break;
        case 'E-mail':
          if (!validateEmail(emailValue)) {
            dispatch({ type: 'INVALID_EMAIL' });
          } else {
            dispatch({ type: 'VALID_EMAIL' });
            checkEmailExists(emailValue);
          }
          break;
        case 'Work Position':
          if (!validateField(workPositionValue)) {
            dispatch({ type: 'INVALID_WORK_POSITION' });
          } else {
            dispatch({ type: 'VALID_WORK_POSITION' });
          }
          break;
        case 'review':
          if (!validateField(reviewValue)) {
            dispatch({ type: 'INVALID_REVIEW' });
          } else {
            dispatch({ type: 'VALID_REVIEW' });
          }
          break;
        case 'Upload a photo':
          if (!validateImage(imageName)) {
            dispatch({ type: 'INVALID_IMAGE' });
          } else {
            dispatch({ type: 'VALID_IMAGE' });
          }
          break;
        default:
          break;
      }
    },
    [nameValue, emailValue, workPositionValue, reviewValue, imageValue]
  );

  const onHandleKeyDown = useCallback(e => {
    if (e.keyCode === 13) {
      e.preventDefault();
      return false;
    } else {
      return true;
    }
  }, []);

  return (
    <>
      {isFeedbackCorrect ? (
        <Thanks openModal={openModal} />
      ) : (
        <div className='modal-wrapper'>
          <p className='modal-title'>{title}</p>
          <form
            onSubmit={handleSubmit}
            className='modal-form'
            onKeyDown={onHandleKeyDown}
          >
            <div className='modal-form-cont'>
              <div className='modal-form-cont-side'>
                <ReviewInput
                  value={nameValue}
                  dispatch={dispatch}
                  isValid={isNameValid}
                  isExists
                  type='text'
                  name='Name'
                  setActionType='SET_NAME'
                  setValid='VALID_NAME'
                  validate={validateFields}
                  fetching={false}
                />
                <ReviewInput
                  value={emailValue}
                  dispatch={dispatch}
                  isValid={isEmailValid}
                  isExists={isEmailExists}
                  type='email'
                  name='E-mail'
                  setActionType='SET_EMAIL'
                  setValid='VALID_EMAIL'
                  setExist='EXISTS_EMAIL'
                  validate={validateFields}
                  text='* Our colleague will contact you to confirm the review after some time.'
                  fetching={fetching}
                />
                <ReviewInput
                  value={workPositionValue}
                  dispatch={dispatch}
                  isValid={isWorkPositionValid}
                  isExists
                  type='text'
                  name='Work Position'
                  setActionType='SET_WORK_POSITION'
                  setValid='VALID_WORK_POSITION'
                  validate={validateFields}
                  fetching={false}
                />
              </div>
              <div className='modal-form-cont-side'>
                <FileUploader
                  value={imageValue}
                  dispatch={dispatch}
                  isValid={isImageValid}
                  isExist={isImageExists}
                  name='Upload a photo'
                  setActionType='SET_IMAGE'
                  validate={validateFields}
                />
                <ReviewPhoto
                  isImageValid={isImageValid}
                  imageValue={imageValue}
                  dispatch={dispatch}
                  setActionType='SET_IMAGE'
                />
              </div>
            </div>

            <ReviewTextArea
              type='text'
              value={reviewValue}
              dispatch={dispatch}
              isValid={isReviewValid}
              name='review'
              setActionType='SET_REVIEW'
              setValid='VALID_REVIEW'
              validate={validateFields}
            />
            <div className='modal-form-button-cont'>
              <ReviewButton
                onClick={openModal}
                text='Cancel'
                className='cancel-review-modal btn btn-primary'
                isOpen={false}
                type='button'
              />
              <ReviewButton
                text='Send'
                className='send-review-modal btn btn-primary'
                isOpen
                type='submit'
                loading={formSending}
              />
            </div>
          </form>
        </div>
      )}
    </>
  );
};

export default ReviewForm;

ReviewForm.propTypes = {
  openModal: PropTypes.func,
  title: PropTypes.string
};
