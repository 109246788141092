import React from 'react';
import PropTypes from 'prop-types';

import ReviewButton from './ReviewButton';
import './Thanks.scss';

const Thanks = ({ openModal }) => (
  <div className='modal-thanks'>
    <div className='modal-thanks-title'>
      <p>Done</p>
    </div>
    <div className='modal-thanks-body'>
      <p>Thanks for your feedback!</p>
      <ReviewButton
        onClick={openModal}
        text='Next'
        className='next-review-modal btn btn-primary'
        isOpen={false}
        type='button'
      />
    </div>
  </div>
);

export default Thanks;

Thanks.propTypes = {
  openModal: PropTypes.func
};
