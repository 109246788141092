import React from 'react';
import PropTypes from 'prop-types';

const SubmitButton = ({ text, className, type }) => (
  <button className={className} type={type}>
    {text}
  </button>
);

export default SubmitButton;

SubmitButton.propTypes = {
  text: PropTypes.string,
  className: PropTypes.string,
  type: PropTypes.string
};
