import React, { useState, useEffect } from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import Navigation from './Navigation';

import './Slider.scss';
import './Slide.scss';

const query = graphql`
  query slider {
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/slider/" } }
      sort: { fields: frontmatter___order }
    ) {
      nodes {
        html
        frontmatter {
          order
        }
      }
    }
  }
`;

const Slider = () => {
  const [slide, setSlide] = useState(0);
  const data = useStaticQuery(query);
  const {
    allMarkdownRemark: { nodes }
  } = data;

  useEffect(() => {
    const interval = setInterval(() => {
      setSlide(slide + 1 > nodes.length - 1 ? 0 : slide + 1);
    }, 5000);

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  });

  return (
    <div className='slider'>
      <h2>Reviews</h2>
      <div className='slider-wrapper'>
        {nodes.map(({ html, frontmatter: { order } }) => (
          <div
            className={`slide slide-${slide}`}
            key={order}
            dangerouslySetInnerHTML={{ __html: html }}
          />
        ))}
        <div
          className={`slide desktop-slide slide-${slide}`}
          dangerouslySetInnerHTML={{ __html: nodes[0].html }}
        />
      </div>
      <Navigation slides={nodes} slide={slide} setSlide={setSlide} />
    </div>
  );
};

export default Slider;
