const API_URL = '';
export const withUrl = path => API_URL + path;

const CHECK_MAIL = '/checkMail';

function fetchJSON(...args) {
  return fetch(...args).then(d => d.json());
}

export function checkEmail(email) {
  return fetchJSON(withUrl(CHECK_MAIL), {
    method: 'POST',
    headers: {
      'Cache-Control': 'no-cache',
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    body: `email=${encodeURIComponent(email)}`
  });
}
