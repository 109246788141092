import React from 'react';
import PropTypes from 'prop-types';

import usePreviosValue from '../../hooks/usePreviosValue';
import './ReviewTextArea.scss';

const ReviewTextArea = ({
  value,
  name,
  dispatch,
  isValid,
  setActionType,
  validate
}) => {
  const previosValue = usePreviosValue(value);

  return (
    <div className='form-textarea'>
      <p>{name}</p>
      <textarea
        placeholder='Review'
        className='review'
        value={value}
        onChange={e =>
          dispatch({
            type: setActionType,
            payload: e.target.value
          })
        }
        onBlur={
          value && previosValue !== value ? () => validate(name) : () => {}
        }
        required
      />
      {!isValid && <p>{name} is not valid</p>}
    </div>
  );
};

export default ReviewTextArea;

ReviewTextArea.propTypes = {
  dispatch: PropTypes.func,
  value: PropTypes.string,
  isValid: PropTypes.bool,
  isExist: PropTypes.bool,
  text: PropTypes.string,
  name: PropTypes.string,
  setActionType: PropTypes.string,
  validate: PropTypes.func
};
