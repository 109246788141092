import React, { useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import CloseIcon from './CloseIcon';
import Portal from './Portal';
import ReviewForm from './ReviewForm';

import './ReviewModal.scss';

const ReviewModal = ({ title, openModal }) => {
  const modal = useRef();

  const handleClickOutside = useCallback(e => {
    if (modal && !modal.current.contains(e.target)) {
      openModal(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <Portal>
      <div ref={modal} className='modal'>
        <CloseIcon openModal={openModal} />
        <ReviewForm openModal={openModal} title={title} />
      </div>
    </Portal>
  );
};

export default ReviewModal;

ReviewModal.propTypes = {
  title: PropTypes.string,
  openModal: PropTypes.func
};
