import React from 'react';
import PropTypes from 'prop-types';

const ItemBlock = ({ name, value }) => (
  <div className='item-block'>
    <p className='item-label'>{name}</p>
    <p className='item-result'>{value}</p>
  </div>
);

ItemBlock.propTypes = {
  name: PropTypes.string,
  value: PropTypes.node
};

export default ItemBlock;
