export const initState = () => ({
  nameValue: '',
  emailValue: '',
  workPositionValue: '',
  reviewValue: '',
  imageValue: '',
  isNameValid: true,
  isEmailValid: true,
  isWorkPositionValid: true,
  isReviewValid: true,
  isImageValid: true,
  isEmailExists: true,
  fetching: false,
  isImageExists: true,
  formSending: false
});

export const reducer = (state, { type, payload }) => {
  switch (type) {
    case 'SET_NAME':
      return {
        ...state,
        nameValue: payload
      };
    case 'SET_EMAIL':
      return {
        ...state,
        emailValue: payload
      };
    case 'SET_WORK_POSITION':
      return {
        ...state,
        workPositionValue: payload
      };
    case 'SET_REVIEW':
      return {
        ...state,
        reviewValue: payload
      };
    case 'SET_IMAGE':
      return {
        ...state,
        imageValue: payload
      };
    case 'INVALID_NAME':
      return {
        ...state,
        isNameValid: false
      };
    case 'INVALID_EMAIL':
      return {
        ...state,
        isEmailValid: false
      };
    case 'INVALID_WORK_POSITION':
      return {
        ...state,
        isWorkPositionValid: false
      };
    case 'INVALID_REVIEW':
      return {
        ...state,
        isReviewValid: false
      };
    case 'INVALID_IMAGE':
      return {
        ...state,
        isImageValid: false
      };
    case 'VALID_NAME':
      return {
        ...state,
        isNameValid: true
      };
    case 'VALID_EMAIL':
      return {
        ...state,
        isEmailValid: true
      };
    case 'VALID_WORK_POSITION':
      return {
        ...state,
        isWorkPositionValid: true
      };
    case 'VALID_REVIEW':
      return {
        ...state,
        isReviewValid: true
      };
    case 'VALID_IMAGE':
      return {
        ...state,
        isImageValid: true
      };
    case 'NOT_EXISTS_IMAGE':
      return {
        ...state,
        isImageExists: false
      };
    case 'EXISTS_IMAGE':
      return {
        ...state,
        isImageExists: true
      };
    case 'NOT_EXISTS_EMAIL':
      return {
        ...state,
        isEmailExists: false,
        fetching: false
      };
    case 'EXISTS_EMAIL':
      return {
        ...state,
        isEmailExists: true,
        fetching: false
      };
    case 'FETCHING':
      return {
        ...state,
        fetching: true
      };
    case 'FORM_SENDING':
      return {
        ...state,
        formSending: true
      };
    case 'FORM_SENT':
      return {
        ...state,
        formSending: false
      };
    default:
      return state;
  }
};
