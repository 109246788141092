import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';

import './FileUploader.scss';

const FileUploader = ({
  value,
  name,
  dispatch,
  isValid,
  isExist,
  setActionType,
  validate
}) => {
  useEffect(() => {
    if (value) {
      validate(name);
    }
  }, [value]);

  const onDrop = useCallback(acceptedFiles => {
    dispatch({ type: setActionType, payload: acceptedFiles[0] });
  }, []);

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  const { name: imageName } = value;

  return (
    <div className='form-row'>
      <p>{name}</p>
      <div className='file-upload' {...getRootProps()}>
        <div className='input-container'>
          <input
            {...getInputProps()}
            onFocus={() => dispatch({ type: 'EXISTS_IMAGE' })}
          />
          <p>{imageName}</p>
        </div>

        <div className='button-container'>
          <button type='button'></button>
        </div>
      </div>
      {!isValid && <p>file is not an image</p>}
      {!isExist && (
        <p className='input-hint error-label'>please, attach your photo</p>
      )}
    </div>
  );
};

export default FileUploader;

FileUploader.propTypes = {
  dispatch: PropTypes.func,
  value: PropTypes.object,
  isValid: PropTypes.bool,
  isExist: PropTypes.bool,
  text: PropTypes.string,
  type: PropTypes.string,
  name: PropTypes.string,
  setActionType: PropTypes.string,
  validate: PropTypes.func
};
